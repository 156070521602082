::-webkit-scrollbar {
  width: 0px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: #3b3b3c;
  border-radius: 20px;
  background-clip: content-box;
  border: 6px solid transparent;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #5e5e5e;
}

@keyframes swirl-in-fwd {
  0% {
    transform: rotate(-540deg) scale(0);
    opacity: 0;
  }
  100% {
    transform: rotate(0) scale(1);
    opacity: 1;
  }
}

.swirl-in-fwd {
  animation: swirl-in-fwd 0.3s ease-out both;
}

:root {
  --toastify-color-success: #08C58A !important;
  --toastify-color-error: #FF025C !important;
  --toastify-icon-color-success: var(--toastify-color-success) !important;
  --toastify-icon-color-error: var(--toastify-color-error) !important;
}

.Toastify__toast-container--bottom-right {
  text-align: center !important;
}

.Toastify__close-button--light {
  color: #fff !important;
}

.Toastify__toast--modal {
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  width: 180px !important;
  padding: 0px !important;
}

.Toastify__toast--modal .Toastify__toast-body {
  padding: 0px !important;
  width: 180px !important;
}

.Toastify__toast--modal.Toastify__toast-theme--colored.Toastify__toast--default {
  background: transparent !important;
  width: 180px !important;
}